<template>
  <div v-if="fetching" class="loading_spinner_box">
    <div class="loading_spinner"></div>
  </div>
  <template v-else>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="
          intro-y
          col-span-12
          flex flex-wrap
          sm:flex-nowrap
          items-center
          mt-2
        "
      >
        <div class="dropdown">
          <button
            class="
              dropdown-toggle
              btn
              px-2
              box
              text-gray-700
              dark:text-gray-300
            "
            aria-expanded="false"
          >
            <span class="w-5 h-5 flex items-center justify-center">
              <i class="w-4 h-4 bx bx-plus text-lg"></i>
            </span>
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                  rounded-md
                "
              >
                <i class="bx bx-printer text-lg w-4 h-4 mr-2"></i> Print
              </a>
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                  rounded-md
                "
              >
                <i class="bx bx-file text-lg w-4 h-4 mr-2"></i> Export to Excel
              </a>
              <a
                href=""
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                  rounded-md
                "
              >
                <i class="bx bxs-file-pdf text-lg w-4 h-4 mr-2"></i> Export to
                PDF
              </a>
            </div>
          </div>
        </div>
        <div class="hidden md:block mx-auto text-gray-600">
          Showing 1 to 10 of 150 entries
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-13"
              placeholder="Search..."
            />
            <i
              class="
                bx bx-search-alt
                text-lg
                w-4
                h-4
                absolute
                my-auto
                inset-y-0
                mr-3
                right-0
              "
            ></i>
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">IMAGES</th>
              <th class="whitespace-nowrap">PRODUCT NAME</th>
              <th class="text-center whitespace-nowrap">STOCK</th>
              <th class="text-center whitespace-nowrap">STATUS</th>
              <th class="text-center whitespace-nowrap">ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-14.jpg"
                      title="Uploaded at 30 May 2020"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-14.jpg"
                      title="Uploaded at 30 May 2020"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-5.jpg"
                      title="Uploaded at 30 May 2020"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap">Dell XPS 13</a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  PC &amp; Laptop
                </div>
              </td>
              <td class="text-center">86</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-6">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Inactive
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-2.jpg"
                      title="Uploaded at 4 April 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-7.jpg"
                      title="Uploaded at 4 April 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-6.jpg"
                      title="Uploaded at 4 April 2021"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap"
                  >Apple MacBook Pro 13</a
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  PC &amp; Laptop
                </div>
              </td>
              <td class="text-center">147</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Active
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-15.jpg"
                      title="Uploaded at 20 November 2022"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-2.jpg"
                      title="Uploaded at 20 November 2022"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-7.jpg"
                      title="Uploaded at 20 November 2022"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap">Nike Tanjun</a>
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  Sport &amp; Outdoor
                </div>
              </td>
              <td class="text-center">167</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-6">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Inactive
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-14.jpg"
                      title="Uploaded at 24 February 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-4.jpg"
                      title="Uploaded at 24 February 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-8.jpg"
                      title="Uploaded at 24 February 2021"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap"
                  >Samsung Galaxy S20 Ultra</a
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  Smartphone &amp; Tablet
                </div>
              </td>
              <td class="text-center">86</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-6">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Inactive
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-3.jpg"
                      title="Uploaded at 4 October 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-9.jpg"
                      title="Uploaded at 4 October 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-4.jpg"
                      title="Uploaded at 4 October 2021"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap"
                  >Sony Master Series A9G</a
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  Electronic
                </div>
              </td>
              <td class="text-center">103</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Active
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-1.jpg"
                      title="Uploaded at 8 March 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-3.jpg"
                      title="Uploaded at 8 March 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-4.jpg"
                      title="Uploaded at 8 March 2021"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap"
                  >Samsung Galaxy S20 Ultra</a
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  Smartphone &amp; Tablet
                </div>
              </td>
              <td class="text-center">50</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Active
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-10.jpg"
                      title="Uploaded at 12 January 2022"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-3.jpg"
                      title="Uploaded at 12 January 2022"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-14.jpg"
                      title="Uploaded at 12 January 2022"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap"
                  >Oppo Find X2 Pro</a
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  Smartphone &amp; Tablet
                </div>
              </td>
              <td class="text-center">113</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-6">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Inactive
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-9.jpg"
                      title="Uploaded at 4 May 2020"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-5.jpg"
                      title="Uploaded at 4 May 2020"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-4.jpg"
                      title="Uploaded at 4 May 2020"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap"
                  >Apple MacBook Pro 13</a
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  PC &amp; Laptop
                </div>
              </td>
              <td class="text-center">88</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-6">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Inactive
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
            <tr class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit zoom-in">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-10.jpg"
                      title="Uploaded at 23 December 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-5.jpg"
                      title="Uploaded at 23 December 2021"
                    />
                  </div>
                  <div class="w-10 h-10 image-fit zoom-in -ml-5">
                    <img
                      alt="Rubick Tailwind HTML Admin Template"
                      class="tooltip rounded-full"
                      src="media/images/preview-13.jpg"
                      title="Uploaded at 23 December 2021"
                    />
                  </div>
                </div>
              </td>
              <td>
                <a href="" class="font-medium whitespace-nowrap"
                  >Apple MacBook Pro 13</a
                >
                <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                  PC &amp; Laptop
                </div>
              </td>
              <td class="text-center">50</td>
              <td class="w-40">
                <div class="flex items-center justify-center text-theme-9">
                  <i class="bx bx-check-square text-lg w-4 h-4 mr-2"></i>
                  Active
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <a class="flex items-center mr-3" href="javascript:;">
                    <i class="bx bx-edit text-lg w-4 h-4 mr-1"></i>
                    Edit
                  </a>
                  <a
                    class="flex items-center text-theme-6"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <i class="bx bx-trash text-lg w-4 h-4 mr-1"></i> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <div
        class="
          intro-y
          col-span-12
          flex flex-wrap
          sm:flex-row sm:flex-nowrap
          items-center
        "
      >
        <ul class="pagination">
          <li>
            <a class="pagination__link" href="">
              <i class="w-4 h-4 text-lg bx bx-chevrons-left"></i>
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <i class="w-4 h-4 text-lg bx bx-chevron-left"></i>
            </a>
          </li>
          <li><a class="pagination__link" href="">...</a></li>
          <li><a class="pagination__link" href="">1</a></li>
          <li>
            <a class="pagination__link pagination__link--active" href="">2</a>
          </li>
          <li><a class="pagination__link" href="">3</a></li>
          <li><a class="pagination__link" href="">...</a></li>
          <li>
            <a class="pagination__link" href="">
              <i class="w-4 h-4 text-lg bx bx-chevron-right"></i>
            </a>
          </li>
          <li>
            <a class="pagination__link" href="">
              <i class="w-4 h-4 text-lg bx bx-chevrons-right"></i>
            </a>
          </li>
        </ul>
        <select class="w-20 form-select box mt-3 sm:mt-0">
          <option>10</option>
          <option>25</option>
          <option>35</option>
          <option>50</option>
        </select>
      </div>
      <!-- END: Pagination -->
    </div>
  </template>
</template>
<script>
import { ref } from "vue";
export default {
  components: {},
  setup() {
    const title = ref("Orders");
    const fetching = ref(false);
    return {
      title,
      fetching,
    };
  },
};
</script>
